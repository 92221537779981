<template>
  <div class="landing-features">
    <div v-for="(feature, index) in features_list" :key="index" class="feature-item">
      <img :src="require(`@/assets/images/main/landing_mobile/features/${feature}.svg`)" alt="feature-icon">
      <p>{{ $t(feature) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
  data() {
    return {
      features_list: [
        "video_chat",
        "meet_people_globally",
        "get_rewards",
        "have_fun"
      ]
    }
  }
}
</script>

<style lang="scss">
.landing-features {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media screen and (max-width: 560px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  .feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-top: 30px;

    img {
      width: 80px;
      height: 80px;
      margin-bottom: 15px;
    }
  }
}
</style>
