const client_strings = [
  {s:'Windows 3.11', r:/Win16/},
  {s:'Windows 95', r:/(Windows 95|Win95|Windows_95)/},
  {s:'Windows ME', r:/(Win 9x 4.90|Windows ME)/},
  {s:'Windows 98', r:/(Windows 98|Win98)/},
  {s:'Windows CE', r:/Windows CE/},
  {s:'Windows 2000', r:/(Windows NT 5.0|Windows 2000)/},
  {s:'Windows XP', r:/(Windows NT 5.1|Windows XP)/},
  {s:'Windows Server 2003', r:/Windows NT 5.2/},
  {s:'Windows Vista', r:/Windows NT 6.0/},
  {s:'Windows 7', r:/(Windows 7|Windows NT 6.1)/},
  {s:'Windows 8', r:/(Windows 8|Windows NT 6.2)/},
  {s:'Windows 8.1', r:/(Windows 8.1|Windows NT 6.3)/},
  {s:'Windows 10', r:/(Windows 10|Windows NT 10.0)/},
  {s:'Windows NT 4.0', r:/(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
  {s:'Windows ME', r:/Windows ME/},
  {s:'Kindle', r:/Kindle|Silk|KFTT|KFOT|KFJWA|KFJWI|KFSOWI|KFTHWA|KFTHWI|KFAPWA|KFAPWI/},//more priority than android
  {s:'Android', r:/Android/},
  {s:'Open BSD', r:/OpenBSD/},
  {s:'Sun OS', r:/SunOS/},
  {s:'Linux', r:/(Linux|X11)/},
  {s:'iOS', r:/(iPhone|iPad|iPod)/},
  {s:'Mac OS X', r:/Mac OS X/},
  {s:'Mac OS', r:/(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
  {s:'QNX', r:/QNX/},
  {s:'UNIX', r:/UNIX/},
  {s:'BeOS', r:/BeOS/},
  {s:'OS/2', r:/OS\/2/},
  {s:'Search Bot', r:/(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
];

export const getOs = () => {
  let os;

  for (let index in client_strings) {
    if (client_strings[index].r.test(navigator.userAgent)) {
      os = client_strings[index].s;
      break;
    }
  }

  if (os.indexOf('Mac OS') !== -1){
    os = 'macosx';
  } else if (os.indexOf('Android') !== -1){
    os = 'android';
  } else if (os.indexOf('Kindle') !== -1){
    os = 'kindle';
  } else if (os === 'iOS') {
    os = 'ios';
  } else {
    os = 'windows';
  }

  return os;
}
