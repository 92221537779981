<template>
  <div class="testimonials-container">
    <swiper ref="swiper" class="testimonials-swiper" :options="swiperOption">
      <swiper-slide v-for="(testimonial, index) in testimonials" :key="index">
        <div class="testimonial-item">
          <div class="testimonial-user">
            <img alt="user-avatar" :src="require(`@/assets/images/main/${testimonial.image}`)">
            <p>{{ testimonial.name }}</p>
          </div>
          <p>“{{ $t(testimonial.text_id) }}“</p>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination" />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: "Testimonials",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      testimonials: [
        {
          image: 'stub_avatars/kristin_fox.png',
          text_id: 'testimonials.excellent_program',
          name: 'Kristin Fox'
        },
        {
          image: 'stub_avatars/don_russell.png',
          text_id: 'testimonials.helpful',
          name: 'Don Russell'
        },
        {
          image: 'stub_avatars/esther_howard.png',
          text_id: 'testimonials.great_app',
          name: 'Esther Howard'
        },
        {
          image: 'stub_avatars/eleanor_pena.png',
          text_id: 'testimonials.love_this',
          name: 'Eleanor Pena'
        },
      ],
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: (index, className) => `<span class="${className}"></span>`
        }
      },
    }
  }
}
</script>

<style lang="scss">
.testimonials-container {

  .swiper-container {
    height: 205px;

    @media screen and (max-width: 560px) {
      height: 171px;
    }
  }
  
  .swiper-slide {
    width: 80%;
    max-width: 460px;

    @media screen and (max-width: 560px) {
      max-width: 320px;
    }

    &.swiper-slide-active .testimonial-item {
      background: #fff;
    }
  }

  .swiper-pagination {
    position: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 4px;
      opacity: 1;
      background: rgba(0, 0, 0, 0.24);

      &.swiper-pagination-bullet-active {
        width: 12px;
        height: 12px;
        background: #FFFFFF;
      }
    }
  }

  .testimonial-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 20px 38px rgba(6, 53, 80, 0.06);
    border-radius: 8px;
    padding: 20px 20px 24px;
    height: 171px;
    margin: 0 auto;
    justify-content: space-around;

    @media screen and (max-width: 560px) {
      height: 151px;
    }

    > p {
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #4A4A4A;
      max-width: 420px;
    }

    .testimonial-user {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 560px) {
        flex-direction: row;
      }

      img {
        width: 46px;
        height: 46px;
        border-radius: 100%;

        @media screen and (max-width: 560px) {
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }
      }

      p {
        margin-top: 6px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
      }
    }
  }
}

</style>
