<template>
  <div id="landing-mobile">
    <div id="landing-mobile-container">
      <div class="welcome-header-container">
        <div class="welcome-header-text">
          <a class="camfrog-logo" :href="baseUrl">
            <img src="@/assets/images/main/logo.png" alt="camfrog-logo">
          </a>
          <h1>{{ $t('videochat_and_meet') }}</h1>
          <Button :label="$t('download_camfrog')" class="button-download" @click="downloadCamfrog" />
          <p>{{ $t('100m_downloads') }}</p>
        </div>
        <div class="welcome-header-video">
          <video
            controls
            src="@/assets/video/landing_mobile/google_play_portrait.mp4"
            poster="@/assets/images/main/landing_mobile/video_preview.png"
          />
        </div>
      </div>
      <h2 class="block-header">
        {{ $t('join_camfrog_description') }}
      </h2>
      <h3>{{ $t('start_meeting_people') }}</h3>
      <Features />
      <div class="button-download-wrapper">
        <Button :label="$t('and_much_more')" class="button-download" @click="downloadCamfrog" />
      </div>
      <h2 class="block-header">
        {{ $t('feel_part_of_community') }}
      </h2>
      <Testimonials />
      <div class="button-download-wrapper">
        <Button :label="$t('download_camfrog')" class="button-download" @click="downloadCamfrog" />
      </div>
    </div>
    <div class="footer-container">
      <h2 class="block-header">
        {{ $t('join_conversation') }}
      </h2>
      <h3>{{ $t('start_free_videochat') }}</h3>
      <ButtonsMobileStores class="appstores-buttons-container" />
      <div class="footer-image-container"></div>
    </div>
  </div>
</template>

<script>
import Features from "@/main/components/landing_mobile/Features";
import Testimonials from "@/main/components/landing_mobile/Testimonials";
import ButtonsMobileStores from "@/main/components/buttons/ButtonsMobileStores";
import {getOs} from "@/services/client_info";

export default {
  name: "LandingMobile",
  components: { ButtonsMobileStores, Testimonials, Features },
  computed: {
    baseUrl: () => process.env.VUE_APP_BASE_URL
  },
  methods: {
    downloadCamfrog() {
      const os = getOs()
      window.open(os === 'ios' ? process.env.VUE_APP_APPSTORE_APP_URL : process.env.VUE_APP_GOOGLE_PLAY_APP_URL)
    }
  }
}
</script>

<style lang="scss">
#landing-mobile {
  background:
    no-repeat top center url("~@/assets/images/main/landing_mobile/background.svg"),
    linear-gradient(197.18deg, #6A56EA 6.87%, #7041CC 93.84%);
  background-size: 100% 100%;
  overflow: hidden;

  h1 {
    font-weight: 800;
    font-size: 54px;
    line-height: 65px;
    color: #FFFFFF;
    margin: 40px 0;

    @media screen and (max-width: 560px) {
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      margin: 30px 0;
      text-align: center;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #FFFFFF;
    text-align: center;
    margin: 100px auto 30px;
    max-width: 560px;

    @media screen and (max-width: 560px) {
      font-size: 24px;
      line-height: 29px;
      margin: 60px auto 20px;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;

    @media screen and (max-width: 560px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .block-header {
    max-width: 560px;
  }
  .footer-container {
    background: #fff;
    margin-top: 84px;
    border-top: 1px solid #fff; /*disable margins collapse*/

    @media screen and (max-width: 560px) {
      margin-top: 48px;
    }

    h2, h3 {
      color: #363636;
    }

    .appstores-buttons-container {
      margin-top: 45px;

      @media screen and (max-width: 560px) {
        margin-top: 40px;
      }
    }
    .footer-image-container {
      width: 100%;
      height: 70vw;
      max-width: 614px;
      max-height: 422px;
      background-image: url(~@/assets/images/main/landing_mobile/devices.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom center;
      margin: 0 auto;
    }
  }
}

#landing-mobile-container {
  max-width: 720px;
  margin: 0 auto;
  padding: 40px 60px 0;

  @media screen and (max-width: 560px) {
    padding: 30px 20px 0;
  }

  .button-download-wrapper {
    text-align: center;
  }

  .button-download {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    width: 246px;
    height: 60px;

    @media screen and (max-width: 560px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .welcome-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 560px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .welcome-header-text {
    display: flex;
    flex-direction: column;
    max-width: 338px;

    @media screen and (max-width: 560px) {
      align-items: center;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
      mix-blend-mode: normal;
      opacity: 0.8;
      width: 246px;
      margin-top: 20px;

      @media screen and (max-width: 560px) {
        margin-top: 10px;
      }
    }

    .camfrog-logo img {
      width: 172px;
    }
  }
  .welcome-header-video {
    display: flex;
    align-items: center;
    width: 220px;

    @media screen and (max-width: 560px) {
      margin-top: 30px;
      max-width: 320px;
      width: 100%;
    }

    video {
      width: 100%;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  .landing-features {
    margin: 15px auto 40px;

    @media screen and (max-width: 560px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .testimonials-container {
    margin: 60px -70% 50px;

    @media screen and (max-width: 560px) {
      margin: 40px -50% 50px;
    }
  }
}
</style>
